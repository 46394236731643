import './Pages.css'

function NotFoundPage() {
    return (
        <>
            <h1>How did you get here? This page doesn't exist!</h1>
        </>
    )
}

export default NotFoundPage